import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

class WaveSelectField extends React.Component {
  render() {
    const value = this.props.value == null ? '' : this.props.value + '';
    const menuItems = this.props.menuItems;
    const tooltipProps = this.props.tooltipProps;

    return (
      <FormControl variant="standard" fullWidth error={this.props.error}>
        <InputLabel>
          {this.props.label}
          {tooltipProps && (
            <Tooltip
              disableFocusListener
              classes={{
                popper: tooltipProps.tooltipPopper,
                tooltip: tooltipProps.tooltipContainer
              }}
              placement={tooltipProps.placement}
              title={tooltipProps.titleComponent}
            >
              <span> ⓘ</span>
            </Tooltip>
          )}
        </InputLabel>
        <Select
          ref={this.props.innerRef}
          variant="standard"
          name={this.props.name}
          onChange={this.props.onChange}
          value={value}
        >
          {this.props.emptyMenuItem ? (
            <MenuItem component="div" key="null" value={null}>
              {this.props.emptyMenuItemLabel || ''}
            </MenuItem>
          ) : (
            ''
          )}
          {this.props.filterByAllItem ? (
            <MenuItem component="div" key="null" value="all">
              {this.props.filterByAllItemLabel || ''}
            </MenuItem>
          ) : (
            ''
          )}
          {menuItems &&
            Object.keys(menuItems).map((obj) => (
              <MenuItem
                component="div"
                key={obj}
                value={menuItems[obj].value || obj}
              >
                {menuItems[obj].label ||
                  menuItems[obj].name ||
                  menuItems[obj].toString()}
                {menuItems[obj].hasOwnProperty('active') &&
                  menuItems[obj].active === false &&
                  ' (deactivated)'}
              </MenuItem>
            ))}
          {this.props.otherItem ? (
            <MenuItem component="div" key="other" value="other">
              {this.props.otherItemLabel || ''}
            </MenuItem>
          ) : (
            ''
          )}
        </Select>
        {this.props.noValidation ? (
          ''
        ) : (
          <FormHelperText>{this.props.helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <WaveSelectField innerRef={ref} {...props} />
));
