import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { unitResponsesStyles } from './styles';
import { parseISO, format } from 'date-fns';
import { vcmUnitResponseStatusOptions } from './options';

const FeedbackGiven = ({ classes, unitResponse }) => {
  if (
    !unitResponse.approver_feedback ||
    unitResponse.status === vcmUnitResponseStatusOptions.approved.id
  ) {
    return null;
  }

  return (
    <>
      {unitResponse.status === vcmUnitResponseStatusOptions.in_review.id ? (
        <Typography color={'textSecondary'} className={classes.body2}>
          The Educator has updated their response since feedback was given
        </Typography>
      ) : null}
      <Typography color={'textSecondary'} className={classes.body2}>
        Feedback given:
      </Typography>
      <Typography color={'textSecondary'} className={classes.body2}>
        {unitResponse.approver_feedback}
      </Typography>
    </>
  );
};

const ApprovedDetails = ({ classes, unitResponse }) => {
  if (
    !unitResponse.approved_at ||
    !unitResponse.last_approver_user ||
    unitResponse.status !== vcmUnitResponseStatusOptions.approved.id
  ) {
    return null;
  }

  return (
    <>
      <Typography color={'textSecondary'} className={classes.body2}>
        {format(parseISO(unitResponse.approved_at), 'EEEE dd MMM yyyy')}
      </Typography>
      <Typography color={'textSecondary'} className={classes.body2}>
        By {unitResponse.last_approver_user.full_name}
      </Typography>
    </>
  );
};

const VcmUnitResponseStatus = ({ classes, row }) => {
  const statusText = useMemo(
    () => vcmUnitResponseStatusOptions[row.status]?.label ?? row.status,
    [row.status]
  );

  return (
    <div className={classes.cell}>
      <Typography className={classes.smallLabel}>{statusText}</Typography>
      <FeedbackGiven classes={classes} unitResponse={row} />
      <ApprovedDetails classes={classes} unitResponse={row} />
    </div>
  );
};

export default withStyles(VcmUnitResponseStatus, unitResponsesStyles, {
  withTheme: true
});
