import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import WaveTextArea from '@common/components/form/WaveTextArea';
import { withStyles } from 'tss-react/mui';
import { unitResponsesStyles } from './styles';

const api = new ApiService();
const UnitResponseRejectModal = ({
  classes,
  isOpened,
  onClose,
  onRefresh,
  unitResponse,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const initialData = {
    approver_feedback: '',
  };
  const [data, setData] = useState(initialData);

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;

      setData({ ...data, [name]: value });
    },
    [data, setData]
  );

  const errorStatus = useCallback(
    (field) => {
      return validationErrors && validationErrors.hasOwnProperty(field);
    },
    [validationErrors]
  );

  const errorHelpers = useCallback(
    (field) => {
      return errorStatus(field) ? validationErrors[field][0] : null;
    },
    [errorStatus, validationErrors]
  );

  const makeFieldProps = useCallback(
    (field, fieldValue, errorField) => {
      return {
        error: errorStatus(errorField || field),
        helperText: errorHelpers(errorField || field),
        name: field,
        value: fieldValue
      };
    },
    [errorStatus, errorHelpers]
  );

  const setErrors = useCallback(
    (json) => {
      if (json.code == 1) {
        setErrorMessage(json.message);
      } else if (json.code == 2) {
        setValidationErrors(json.messages);
      }
    },
    [setErrorMessage, setValidationErrors]
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const url = `/api/v1/vcms/${unitResponse.vcm_id}/unit_responses/${unitResponse.unit.id}/reject`;
      const res = await api.put(url, {vcm_unit_response: data});

      if (res.status === 200) {
        handleClose();
        onRefresh();
      } else if (res.status === 422 || res.status === 400) {
        setErrors(res.json);
        setErrorMessage(res.json.message);
      } else {
        console.error('Unknown response', res);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [data, unitResponse]);

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    setValidationErrors([]);
    setData(initialData);
    onClose();
  }, [setErrorMessage, setValidationErrors, onClose]);

  return (
    <DefaultModal
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={handleClose}
      title="Needs attention"
      action={
        <DefaultButton
          className={classes.button}
          color="secondary"
          onClick={handleSubmit}
          size="small"
          variant="contained"
        >
          Save
        </DefaultButton>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.body2}>Unit: {unitResponse?.unit?.code}</Typography>
        </Grid>
        <Grid item xs={12}>
          <WaveTextArea
            label="Reason"
            placeholder="Enter reason here"
            maxLength={1000}
            rows={4}
            onChange={handleInputChange}
            {...makeFieldProps('approver_feedback', data.approver_feedback)}
          />
        </Grid>
      </Grid>
    </DefaultModal>
  );
};

export default withStyles(UnitResponseRejectModal, unitResponsesStyles, { withTheme: true });
