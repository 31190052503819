import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import ApiService from '@common/services/ApiService';
import EntityManage from '@common/components/EntityManage';
import UserForm from './UserForm';
import UserFormActions from './UserFormActions';
import UserFormSummary from './UserFormSummary';
import { UserPointsHistory } from './user-points-history';
import { UserPointRequest } from './user-point-request';
import uniqBy from "lodash/uniqBy";


const styles = (theme) => ({
  userInformation: {
    marginTop: theme.typography.pxToRem(25),
    marginLeft: theme.typography.pxToRem(25)
  },
  userProfileContainer: {
    marginTop: theme.typography.pxToRem(25)
  },
  userRoles: {
    marginBottom: theme.typography.pxToRem(19),
    paddingTop: theme.typography.pxToRem(16)
  },
  userRolesText: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.33
  }
});

const getVcmUnits = (lastApprovedVcmData) => {
  if (!lastApprovedVcmData) {
    return [];
  }
  return uniqBy(
    lastApprovedVcmData.courses
      .flatMap((course) => course.units)
      .concat(lastApprovedVcmData.units),
    "code",
  ).map((unit) => ({ label: unit.code, code: unit.code }));
}

class UserManage extends EntityManage {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      departmentOptions: [],
      dirty: false,
      domainOptions: [],
      employmentTypeOptions: [],
      errorMessage: null,
      pointsTotal: 0,
      user: null,
      institute: {},
      userId: props.match.params.id,
      validationErrors: {},
      vocationalActivitiesOptions: [],
      activityIntervalOptions: [],
      vcmUnits: [],
      refreshTick: 0
    };
  }

  handleRefresh = () => {
    this.setState({ refreshTick: Date.now() });
  };

  componentDidMount() {
    this.getUserData();
  }

  getUserData = async () => {
    const getInstitutePromise = this.api.get(
      'institutes',
      this.props.institute.id
    );
    const userDataPromise = this.api.get('users', this.state.userId);
    const departmentOptionsPromise = this.api.query(
      '/api/v1/departments/options'
    );
    const domainOptionsPromise = this.api.query('/api/v1/domains/options');
    const employmentTypeOptionsPromise = this.api.query(
      '/api/v1/employment_types/options'
    );
    const vocationalActivitiesOptionsPromise = this.api.query(
      '/api/v1/vocational_activities/options'
    );
    const activityIntervalOptionsPromise = this.api.query(
      '/api/v1/point_requests/activity_intervals'
    );

    try {
      const [
        res,
        institute,
        departmentOptions,
        domainOptions,
        employmentTypeOptions,
        vocationalActivitiesOptions,
        activityIntervalOptions
      ] = await Promise.all([
        userDataPromise,
        getInstitutePromise,
        departmentOptionsPromise,
        domainOptionsPromise,
        employmentTypeOptionsPromise,
        vocationalActivitiesOptionsPromise,
        activityIntervalOptionsPromise
      ]);

      if (res.status === 200) {
        const activityIntervalOptionsHash = {};

        activityIntervalOptions.forEach((option) => {
          activityIntervalOptionsHash[option.value] = option;
        });

        let getLastApproveVcm = null;
        if (
          res.json.data.vcm_enabled &&
          institute.json.data.vocational_currency_uses_units
        ) {
          getLastApproveVcm = await this.api.query(
            `/api/v1/vcms/show_last_approved_vcm?user_id=${res.json.data.id}`
          );
        }

        this.setState({
          departmentOptions,
          domainOptions,
          employmentTypeOptions,
          vocationalActivitiesOptions,
          institute: institute.json.data,
          activityIntervalOptions: activityIntervalOptionsHash,
          vcmUnits: getVcmUnits(getLastApproveVcm?.data)
        });
        this.setEntity(res.json);
      } else {
        this.setState({
          errorMessage: 'Something went wrong. got status=' + res.status
        });
      }
    } catch (e) {
      this.processError(e);
    }
  };

  handleActiveStatus = (activeUser) => {
    const url = '/api/v1/users/' + this.state.userId;
    activeUser ? (activeUser = false) : (activeUser = true);
    this.api
      .put(url, { user: { active: activeUser } })
      .then((res) => {
        this.processResponse(res);
      })
      .catch((ex) => {
        this.processError(ex);
      });
  };

  handleProgramCellClicked = (row) => {
    if (row.type === 'PointsProgram') {
      this.props.history.push('/programs/' + row.program.id);
    }
  };

  handlePublish = (publish) => {
    this.saveData({ published: publish });
  };

  handleUserChange = (newUser) => {
    this.setState({ dirty: true, user: newUser });
  };

  isNew = () => {
    return this.state.userId === 'new';
  };

  processResponse(res) {
    // Calls the default
    super.processResponse(res);

    // Custom stuff
    if (res.status == 201) {
      this.props.history.push('/users/' + res.json.data.id);
    }
  }

  saveData = (proposedAttributes = {}) => {
    this.api
      .saveResource('user', {
        ...this.state.user,
        ...proposedAttributes,
        generate_password: true
      })
      .then((res) => {
        this.processResponse(res);
      })
      .catch((ex) => {
        this.processError(ex);
      });
  };

  setEntity = (json) => {
    const s = { validationErrors: [], dirty: false, errorMessage: null };
    this.setState({ ...s, user: json.data, userId: json.data.id });
  };

  render() {
    const { classes } = this.props;
    const currentUser = this.props.user;
    const {
      dirty,
      domainOptions,
      errorMessage,
      user,
      institute,
      validationErrors,
      vocationalActivitiesOptions,
      activityIntervalOptions,
      vcmUnits,
      refreshTick
    } = this.state;
    const editEnabled =
      currentUser.is_institute_admin || currentUser.is_super_admin;

    return (
      <Grid container className={classes.userProfileContainer} spacing={2}>
        <Grid item xs={6}>
          {user && <UserFormSummary classes={classes} user={user} />}
        </Grid>
        <Grid item xs={6}>
          {user && editEnabled && (
            <UserFormActions
              dirty={dirty}
              errorMessage={errorMessage}
              handleActiveStatus={this.handleActiveStatus}
              onSave={() => this.saveData()}
              user={user}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {user && (
            <UserForm
              departmentFields={this.state.departmentOptions}
              employmentTypeFields={this.state.employmentTypeOptions}
              onUserChange={this.handleUserChange}
              user={user}
              validationErrors={validationErrors}
              institute={institute}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {user &&
            (currentUser.is_super_admin ||
              currentUser.is_institute_admin ||
              currentUser.is_supervisor) && (
              <UserPointRequest
                domainOptions={domainOptions}
                vocationalActivitiesOptions={vocationalActivitiesOptions}
                activityIntervalOptions={activityIntervalOptions}
                pointsFileAttachmentEnabled={
                  institute.points_file_attachment_enabled
                }
                allowSupervisorToAction={institute.allow_supervisor_to_action}
                refreshTick={refreshTick}
                onRefresh={this.handleRefresh}
                user={user}
                currentUser={currentUser}
                vcmUnits={vcmUnits}
                vocationalCurrencyUsesUnits={
                  user.vcm_enabled && institute.vocational_currency_uses_units
                }
              />
            )}
        </Grid>
        <Grid item xs={12}>
          {user && !user.is_super_admin && (
            <UserPointsHistory
              currentUser={currentUser}
              domainOptions={domainOptions}
              activityIntervalOptions={activityIntervalOptions}
              pointsFileAttachmentEnabled={
                institute.points_file_attachment_enabled
              }
              onProgramCellClicked={this.handleProgramCellClicked}
              user={user}
              vocationalActivitiesOptions={vocationalActivitiesOptions}
              refreshTick={refreshTick}
              onRefresh={this.handleRefresh}
              vcmUnits={vcmUnits}
              vocationalCurrencyUsesUnits={
                user.vcm_enabled && institute.vocational_currency_uses_units
              }
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(UserManage, styles, { withTheme: true });
