export const vcmStatusOptions = {
  draft: {
    id: 'draft',
    label: 'Draft'
  },
  in_review: {
    id: 'in_review',
    label: 'In Review'
  },
  needs_attention: {
    id: 'needs_attention',
    label: 'Needs Attention'
  },
  approved: {
    id: 'approved',
    label: 'Approved'
  },
  closed: {
    id: 'closed',
    label: 'Closed'
  }
};

export const vcmUnitCurrencyOptions = {
  ['false']: {
    id: 'not_current',
    label: 'Not Current'
  },
  ['true']: {
    id: 'current',
    label: 'Current'
  }
};

export const vcmUnitResponseStatusOptions = {
  unsubmitted: {
    id: 'unsubmitted',
    label: 'Unsubmitted'
  },
  saved: {
    id: 'saved',
    label: 'Saved'
  },
  in_review: {
    id: 'in_review',
    label: 'In Review'
  },
  needs_attention: {
    id: 'needs_attention',
    label: 'Needs Attention'
  },
  approved: {
    id: 'approved',
    label: 'Approved'
  }
};


export const vcmUnitResponseMethodOptions = {
  current_unit: {
    id: 'current_unit',
    label: 'I hold the current unit of competency'
  },
  equivalent_unit: {
    id: 'equivalent_unit',
    label: 'I hold a superseded and equivalent unit of competency'
  },
  relevant_study: {
    id: 'relevant_study',
    label: 'I hold a recognition of relevant study'
  },
  work_experience: {
    id: 'work_experience',
    label: 'I have vocational work experience'
  }
};