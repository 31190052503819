import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import FileIcon from '@common/icons/FileIcon';
import { unitResponsesStyles } from './styles';


const SupportingDocuments = ({ classes, supporting_documents }) => {
  const supportingDocuments = supporting_documents || [];
  if (!supportingDocuments.length) {
    return null;
  }

  return (
    <div>
      <Typography className={classes.label}>Supporting documents:</Typography>
      {supportingDocuments.map((doc, index) => (
        <Fragment key={doc.id ?? index}>
          <div className={classes.row}>
            <FileIcon viewBox="0 0 16 16" />
            <div>
              <Typography className={classes.body2}>{doc.title}</Typography>
              <a href={doc.url} target="_blank" rel="noopener noreferrer">
                <Typography className={[classes.body2, classes.link]}>
                  {doc.filename}
                </Typography>
              </a>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};




const VcmProfessionalQualificationDetailCell = ({
  classes,
  row,
  vcm,
}) => {
  if (!vcm || row.removed) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.cell}>
      <SupportingDocuments
            classes={classes}
            supporting_documents={row.supporting_documents}
          />
      </div>
    </div>
  );
};

export default withStyles(VcmProfessionalQualificationDetailCell, unitResponsesStyles, {
  withTheme: true
});
