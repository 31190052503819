const styles = (theme) => ({
    fileUploadContainer: {
      padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
        45
      )} ${theme.typography.pxToRem(25)}`
    },
    importInstructions: {
      padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
        334
      )} ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(25)}`
    },
    list: {
      listStyle: 'none',
      marginTop: 0,
      paddingLeft: theme.typography.pxToRem(25)
    },
    listItem: {
      lineHeight: 1.5,
      marginBottom: theme.typography.pxToRem(6),
      paddingLeft: theme.typography.pxToRem(8),
      textIndent: theme.typography.pxToRem(-26)
    },
    notesHeaders: {
      paddingBottom: theme.typography.pxToRem(15)
    },
    container: {
      marginTop: theme.typography.pxToRem(25),
      marginBottom: theme.typography.pxToRem(53)
    },
    buttonProgressWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    notesBody: {
      paddingBottom: theme.typography.pxToRem(24)
    }
  });

  export default styles;