import { green } from '@mui/material/colors';

export const unitResponsesStyles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  paddedContainer: {
    padding: '0px 24px 16px'
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  body2: {
    fontSize: theme.typography.pxToRem(13)
  },
  smallLabel: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13)
  },
  label: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14)
  },
  borderedContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #00000033',
    borderRadius: '5px',
    padding: '12px 8px',
    marginBottom: '8px',
    width: '100%'
  },
  tableCell: {
    verticalAlign: 'top'
  },
  actionButtons: {
    marginLeft: theme.typography.pxToRem(18)
  },
  approveButton: {
    backgroundColor: green[600]
  },
  rejectButton: {
    backgroundColor: theme.palette.error.dark
  },
  button: {
    marginTop: theme.typography.pxToRem(15)
  },
  link: {
    textDecoration: 'underline'
  }
});
