import React from 'react';
import { withStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import AddPointsActions from './AddPointsActions';
import AdminTable from '@common/components/table/AdminTable';
import MetadataService from '@common/services/MetadataService';
import DetailCell from '../user-point-request/DetailCell';

const fields = [
  'date',
  'detail',
  'points',
  'approval_reason',
  'type_label',
];

class UserPointsHistory extends React.Component {
  meta = new MetadataService();
  state = {};

  handlePointsCellClicked = row => {
    this.props.onProgramCellClicked(row);
  };

  handleApplySorting = (orderBy, order) => {
    this.setState({
      orderBy,
      order
    });
  };

  renderPointsHistory = () => {
    const actions = ['delete'];

    if (this.props.user.id === null) {
      return <div />;
    } else {
      const metadata = {
        ...this.meta.get('points_histories'),
        detail: this.detailMetadata(),
      }
      metadata['type_label'].frontendSort = true;
      
      return (
        <Paper className={this.props.classes.pointsHistory}>
          <AddPointsActions
            currentUser={this.props.currentUser}
            pointsFileAttachmentEnabled={this.props.pointsFileAttachmentEnabled}
            domainOptions={this.props.domainOptions}
            activityIntervalOptions={this.props.activityIntervalOptions}
            onRefreshRequest={this.props.onRefresh}
            user={this.props.user}
            vocationalActivitiesOptions={this.props.vocationalActivitiesOptions}
            order={this.state.order}
            orderBy={this.state.orderBy}
            vcmUnits={this.props.vcmUnits}
            vocationalCurrencyUsesUnits={this.props.vocationalCurrencyUsesUnits}
          />
          <AdminTable
            tableBottomPadding
            actionsSection={actions}
            cantClick={this.canDelete} //special case: same row types
            canDelete={this.canDelete}
            fields={fields}
            metadata={metadata}
            orderBy={'date'}
            order={'desc'}
            onCellClicked={{ detail_string: this.handlePointsCellClicked }}
            refreshTick={this.props.refreshTick}
            resource={`users/${this.props.user.id}/points_histories`}
            handleApplySorting={this.handleApplySorting}
          />
        </Paper>
      );
    }
  };

  detailString = row => {
    return row.description;
  };

  detailMetadata = () => {
    return {
      label: 'Detail',
      type: 'component',
      component: DetailCell,
    };
  };

  canDelete = row => {
    return row.type === 'PointsVocational' || row.type === 'PointsRea';
  };

  render() {
    if (this.props.user == null) {
      return <div>No User</div>;
    }

    return this.renderPointsHistory();
  }
}

const StyledUserPointsHistory = withStyles(UserPointsHistory, {}, { withTheme: true });

export { StyledUserPointsHistory as UserPointsHistory };
