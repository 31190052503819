import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import WaveTextField from '@common/components/form/WaveTextField';
import WaveTextArea from '@common/components/form/WaveTextArea';
import WaveRichTextEditor from '@common/components/form/WaveRichTextEditor';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import MetadataService from '@common/services/MetadataService';

const styles = (theme) => ({
  container: {
    padding: theme.typography.pxToRem(25),
    paddingTop: theme.typography.pxToRem(22)
  },
  header: {
    paddingBottom: theme.typography.pxToRem(12)
  },
  tooltipContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    boxShadow: '0 2px 10px 0 #00000028',
    padding: theme.typography.pxToRem(20),
    opacity: 1
  },
  tooltipText: {
    color: '#0c0606de',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5
  },
  popper: {
    opacity: 1
  }
});

class Vcm extends React.Component {
  meta = new MetadataService();

  errorHelpers = (field) => {
    if (this.props.validationErrors.hasOwnProperty(field)) {
      return this.props.validationErrors[field][0];
    }
    return null;
  };

  errorStatus = (field) => {
    return this.props.validationErrors.hasOwnProperty(field);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let institute = { ...this.props.instituteDetails };
    institute[name] = value;

    this.props.onChange(institute);
  };

  makeFieldProps = (field) => {
    return {
      error: this.errorStatus(field),
      helperText: this.errorHelpers(field),
      name: field,
      onChange: this.handleInputChange,
      value: this.props.instituteDetails[field]
    };
  };

  render() {
    const { classes } = this.props;
    return (
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.container}>
              <Typography className={classes.header} variant="h6">
                Currency
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <WaveTextField
                    label="Time Vocational Currency Considered Valid (Months)"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <Typography className={classes.tooltipText}>
                          Educators are considered 'current' for a unit if they
                          have approved Vocational Currency with an Activity
                          Date within the number of months specified here.
                        </Typography>
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...this.makeFieldProps(
                      'vocational_currency_valid_month_count'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultCheckbox
                    label="Use VCM unit codes for Vocational Currency"
                    checkboxValue={true}
                    unCheckValue={false}
                    {...this.makeFieldProps('vocational_currency_uses_units')}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.container}>
              <Typography className={classes.header} variant="h6">
                Relevant study justification example
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <WaveTextField
                    label="Description"
                    maxLength={500}
                    {...this.makeFieldProps('vcm_study_example_description')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WaveTextField
                    label="Link to more examples URL"
                    maxLength={2000}
                    {...this.makeFieldProps('vcm_study_example_info_url')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WaveRichTextEditor
                    label="Justification of competency example"
                    {...this.makeFieldProps('vcm_study_example_justification')}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.container}>
              <Typography className={classes.header} variant="h6">
                Vocational work experience justification example
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <WaveTextArea
                    label="Work experience"
                    rows={3}
                    maxLength={500}
                    placeholder={`Employer \nRole \nStart - End`}
                    {...this.makeFieldProps(
                      'vcm_experience_example_description'
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WaveTextField
                    label="Link to more examples URL"
                    maxLength={2000}
                    {...this.makeFieldProps('vcm_experience_example_info_url')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WaveRichTextEditor
                    label="Justification of competency example"
                    {...this.makeFieldProps(
                      'vcm_experience_example_justification'
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.container}>
              <Typography className={classes.header} variant="h6">
                Submit a request to add courses link
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <WaveTextField
                    label="Course request email"
                    type="email"
                    {...this.makeFieldProps('vcm_course_request_email')}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.container}>
              <Typography className={classes.header} variant="h6">
                AQ Instructions link
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <WaveTextField
                    label="Professional Qualification Help Link Label"
                    maxLength={50}
                    {...this.makeFieldProps(
                      'professional_qualification_help_link_label'
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <WaveTextField
                    label="Professional Qualification Help Link URL"
                    maxLength={2000}
                    {...this.makeFieldProps(
                      'professional_qualification_help_link_url'
                    )}
                    helperText="Ensure URL includes https://"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(Vcm, styles, {
  withTheme: true
});
