import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Checkbox,
  FormControl,
  FormHelperText
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import ApiService from '@common/services/ApiService';

const styles = (theme) => ({
  container: {
    padding: '8px 0px',
    border: '1px solid #00000033',
    borderRadius: '5px',
    gap: '16px'
  },
  body2: {
    fontSize: theme.typography.pxToRem(13)
  },
  smallLabel: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13)
  }
});

const api = new ApiService();

let WorkExperienceSelect = ({
  ownerUserId,
  classes,
  value,
  onChange,
  error,
  helperText,
  setErrorMessage
}) => {
  const [allWorkExperiences, setAllWorkExperiences] = useState();

  useEffect(() => {
    api.get('vcm_work_experiences',`?q[owner_user_id_eq]=${ownerUserId}`).then(res => {
      if(res.status === 200){
        setAllWorkExperiences(res.json.data)
      } else {
        setErrorMessage('Something went wrong. got status=' + res.status)
      }
    }).catch(e => {
      setErrorMessage('Something went wrong. '+ e.message)
    })
  },[ownerUserId, setAllWorkExperiences, setErrorMessage])

  const findIndex = (workExperienceId) => {
    return value.findIndex((we) => we.id === workExperienceId);
  };

  const handleSelectionChange = (we) => (_, checked) => {
    const itemIndex = findIndex(we.id);

    if (checked && itemIndex === -1) {
      onChange(value.concat(we));
    }

    if (!checked && itemIndex > -1) {
      const copyValue = [...value];
      copyValue.splice(itemIndex, 1);
      onChange(copyValue);
    }
  };

  if (!allWorkExperiences?.length) {
    return null;
  }

  return (
    <FormControl variant="standard" fullWidth error={error}>
      <Grid container className={classes.container}>
        {allWorkExperiences.map((we) => (
          <Grid key={we.id} container direction={'row'} alignItems={'center'}>
            <Grid item>
              <Checkbox
                onChange={handleSelectionChange(we)}
                checked={findIndex(we.id) > -1}
              />{' '}
            </Grid>
            <Grid item>
              <Typography className={classes.smallLabel}>
                {we.employer_name}
              </Typography>
              <Typography color={'textSecondary'} className={classes.body2}>
                {we.job_role}
              </Typography>
              <Typography color={'textSecondary'} className={classes.body2}>
                {we.year_from} - {we.year_to}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

WorkExperienceSelect = withStyles(WorkExperienceSelect, styles, {
  withTheme: true
});

export default React.forwardRef((props, ref) => (
  <WorkExperienceSelect innerRef={ref} {...props} />
));
