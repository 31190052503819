import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import format from 'date-fns/format';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chisholm from '@common/config/Chisholm';
import Utils from '@common/services/Utils';

import ApiService from '../../../common/services/ApiService';
import Autocomplete from '../../../common/components/autocomplete/Autocomplete';
import WaveSelectField from '../../../common/components/form/WaveSelectField';
import { WaveDatePicker } from '../../../common/components/WaveDatePicker';
import { vcmStatusOptions, vcmUnitCurrencyOptions } from './options';

const styles = (theme) => ({
  adjustedSpacing: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingLeft: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      20
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingRight: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingRole: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`
  },
  adjustedSpacingActive: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(20)}`
  },
  adjustedSpacingDate: {
    padding: `${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      20
    )} ${theme.typography.pxToRem(40)} ${theme.typography.pxToRem(25)}`
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer'
    },
    '&:visited': {
      color: theme.typography.color.primary
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  ieFix: Utils.ieDatePickerFix()
});

const INITIAL_FILTERS = {
  status: vcmStatusOptions.in_review.id
};

class VcmFilters extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      division_id: 'all',
      divisionOptions: {},
      department_id: 'all',
      departmentOptions: {},
      errorMessage: null,
      filters: INITIAL_FILTERS,
      status: vcmStatusOptions.in_review.id,
      supervisor_id: null,
      approver_id: null,
      supervisorLabel: '',
      approverLabel: '',
      date_from_gteq: null,
      date_to_lteq: null, 
      currency: 'all'
    };
  }

  componentDidMount() {
    this.getFiltersOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.division_id !== prevState.division_id) {
      this.setState({ department_id: 'all' });
    }
  }

  arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));

  clearFilters = () => {
    this.setState(
      {
        division_id: 'all',
        department_id: 'all',
        filters: INITIAL_FILTERS,
        status: vcmStatusOptions.in_review.id,
        supervisor_id: null,
        approver_id: null,
        supervisorLabel: '',
        approverLabel: '',
        date_from_gteq: null,
        date_to_lteq: null,
        currency: 'all'
      },
      this.onFilter
    );
  };

  getDepartments = (departments, divisionId) => {
    var departmentsArray = [];
    Object.keys(departments).map((obj) =>
      departments[obj].division_id === divisionId
        ? departmentsArray.push(departments[obj])
        : ''
    );
    return departmentsArray;
  };

  getFiltersOptions = () => {
    const divisionOptionsPromise = this.api.query('/api/v1/divisions/options');
    const departmentOptionsPromise = this.api.query(
      '/api/v1/departments/options'
    );

    Promise.all([divisionOptionsPromise, departmentOptionsPromise])
      .then(([divisionOptions, departmentOptions]) => {
        this.setState({
          divisionOptions: divisionOptions,
          departmentOptions: departmentOptions
        });

        const { onOptionsFetched } = this.props;
        onOptionsFetched();
      })
      .catch(this.processError);
  };

  handleSupervisorAutocompleteChange = (id, value) => {
    this.setState({ supervisor_id: id, supervisorLabel: value });
  };

  handleApproverAutocompleteChange = (id, value) => {
    this.setState({ approver_id: id, approverLabel: value });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  handleDateChange = (name) => (date) => {
    const convertedDate =
      date == null ? null : format(new Date(date), 'yyyy-MM-dd');
    this.setState({ [name]: convertedDate });
  };

  makeFieldProps = (fieldName, fieldValue) => {
    return {
      name: fieldName,
      onChange: this.handleInputChange,
      value: fieldValue
    };
  };

  onFilter = () => {
    let filters = {};
    let params = {};

    if (this.state.division_id != null && this.state.division_id != 'all') {
      filters['owner_user_division_id_eq'] = this.state.division_id;
    }
    if (this.state.department_id != null && this.state.department_id != 'all') {
      filters['owner_user_department_id_eq'] = this.state.department_id;
    }
    if (this.state.supervisor_id != null && this.state.supervisor_id != 'all') {
      filters['owner_user_supervisor_id_eq'] = this.state.supervisor_id;
    }
    if (this.state.approver_id != null && this.state.approver_id != 'all') {
      filters['approver_user_id_eq'] = this.state.approver_id;
    }
    if (this.state.status != null && this.state.status != 'all') {
      filters['status_eq'] = this.state.status;
    }
    if (this.state.date_from_gteq != null) {
      filters['created_at_or_approved_at_gteq'] = this.state.date_from_gteq;
    }
    if (this.state.date_to_lteq != null) {
      filters['created_at_or_approved_at_lteq'] = this.state.date_to_lteq;
    }
    if(this.state.currency !== null && this.state.currency != 'all') {
      params['current'] = this.state.currency;
    }

    this.props.handleApplyFilters(filters, params);
  };

  // unexpected errors
  processError = (ex) => {
    console.error(ex);
    this.setState({ ...s, errorMessage: ex.message });
  };

  render() {
    const { classes } = this.props;
    var {
      division_id,
      divisionOptions,
      department_id,
      departmentOptions,
      status,
      date_from_gteq,
      date_to_lteq,
      currency
    } = this.state;
    var divisionIdInt = parseInt(division_id);
    var departmentsArray = this.getDepartments(
      departmentOptions,
      divisionIdInt
    );
    var filteredDepartments = this.arrayToObject(departmentsArray, 'id');

    return (
      <Fragment>
        <Grid
          container
          className={classes.filterHeader}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Filter</Typography>
          <Grid item>
            <a
              className={classes.clearFilters}
              onClick={() => this.clearFilters()}
            >
              Clear
            </a>
            <Button
              color="secondary"
              onClick={() => this.onFilter()}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Filter</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.adjustedSpacingLeft} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Division"
              menuItems={divisionOptions}
              {...this.makeFieldProps('division_id', division_id)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacing} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Department"
              menuItems={
                divisionIdInt ? filteredDepartments : departmentOptions
              }
              {...this.makeFieldProps('department_id', department_id)}
            />
          </Grid>

          <Grid item className={classes.adjustedSpacingRight} xs={3}>
            <Autocomplete
              label="Supervisor"
              name="supervisor_id"
              onChange={this.handleSupervisorAutocompleteChange}
              role="supervisor"
              value={this.state.supervisorLabel || ''}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingRight} xs={3}>
            <Autocomplete
              label="Approver"
              name="approver_id"
              onChange={this.handleApproverAutocompleteChange}
              role={['supervisor', 'institute_admin']}
              value={this.state.approverLabel || ''}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingRole} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Status"
              menuItems={vcmStatusOptions}
              {...this.makeFieldProps('status', status)}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingDate} xs={3}>
            <WaveDatePicker
              className={classes.ieFix}
              format={Chisholm.rangeDate}
              label="From"
              name="from_date"
              onChange={this.handleDateChange('date_from_gteq')}
              value={date_from_gteq}
            />
          </Grid>
          <Grid item className={classes.adjustedSpacingDate} xs={3}>
            <WaveDatePicker
              className={classes.ieFix}
              format={Chisholm.rangeDate}
              label="To"
              name="to_date"
              onChange={this.handleDateChange('date_to_lteq')}
              value={date_to_lteq}
            />
          </Grid>
          {this.props.showCurrencyFilters ? <Grid item className={classes.adjustedSpacingRole} xs={3}>
            <WaveSelectField
              filterByAllItem
              filterByAllItemLabel="All"
              noValidation
              label="Currency"
              menuItems={vcmUnitCurrencyOptions}
              {...this.makeFieldProps('currency', currency)}
            />
          </Grid> : null}
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(VcmFilters, styles, { withTheme: true });
