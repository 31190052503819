import React from 'react';
import { withStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import {
  listsPlugin,
  MDXEditor,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  quotePlugin,
  headingsPlugin,
  ListsToggle
} from '@mdxeditor/editor';

const MDXPlugins = [
  quotePlugin(),
  listsPlugin(),
  headingsPlugin(),
  toolbarPlugin({
    toolbarContents: () => (
      <>
        <BoldItalicUnderlineToggles />
        <ListsToggle options={['number', 'bullet']} />
      </>
    )
  })
];

const styles = (theme) => ({
  editorContainer: {
    marginTop: 24,
    border: '1px solid #cecece',
    borderRadius: '2px'
  }
});

class WaveRichTextEditor extends React.Component {
  renderFormHelpers(maxLength, value) {
    if (maxLength === 0 || maxLength === undefined || maxLength === null) {
      return <FormHelperText>{this.props.helperText}</FormHelperText>;
    } else {
      return (
        <Grid container direction="row" justifyContent="space-between">
          <FormHelperText>{this.props.helperText}</FormHelperText>
          <FormHelperText error={false}>
            {`${value.length} / ${this.props.maxLength}`}
          </FormHelperText>
        </Grid>
      );
    }
  }

  render() {
    const {
      error,
      label,
      maxLength,
      name,
      onChange,
      readOnly,
      tooltipProps,
      classes
    } = this.props;
    const value = this.props.value == null ? '' : this.props.value + '';

    const handleChange = (newValue) => {
      onChange?.({ target: { name, value: newValue } });
    };

    return (
      <FormControl variant="standard" fullWidth error={error}>
        <InputLabel variant="standard" shrink={true}>
          {label}
          {tooltipProps && (
            <Tooltip
              classes={{
                popper: tooltipProps.tooltipPopper,
                tooltip: tooltipProps.tooltipContainer
              }}
              placement={tooltipProps.placement}
              title={tooltipProps.titleComponent}
            >
              <span> ⓘ</span>
            </Tooltip>
          )}
        </InputLabel>
        <div className={classes.editorContainer}>
          <MDXEditor
            ref={this.props.innerRef}
            name={name}
            onChange={handleChange}
            readOnly={readOnly}
            markdown={value}
            plugins={MDXPlugins}
            contentEditableClassName="custom_content_editable_area"
          />
        </div>

        {this.renderFormHelpers(maxLength, value)}
      </FormControl>
    );
  }
}

WaveRichTextEditor = withStyles(WaveRichTextEditor, styles, {
  withTheme: true
});

export default React.forwardRef((props, ref) => (
  <WaveRichTextEditor innerRef={ref} {...props} />
));
