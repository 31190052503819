import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
  messagesNotesList: {
    listStyle: 'none',
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(40),
    paddingLeft: theme.typography.pxToRem(25)
  },
  importErrorTitle: {
    color: '#d0021b',
    paddingBottom: theme.typography.pxToRem(15)
  },
  listItem: {
    lineHeight: 1.5,
    marginBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(8),
    textIndent: theme.typography.pxToRem(-26)
  },
  headingItem: {
    lineHeight: 1.5,
    fontWeight: 800,
    marginBottom: theme.typography.pxToRem(6),
    paddingLeft: theme.typography.pxToRem(8),
    textIndent: theme.typography.pxToRem(-53),
    listStyleType: 'none',
    ['&:before']:{
      content: '""'
    },
  },
  summaryReport: {
    padding: `${theme.typography.pxToRem(25)} 0 ${theme.typography.pxToRem(
      45
    )} ${theme.typography.pxToRem(25)}`
  },
  summaryReportContainer: {
    marginTop: theme.typography.pxToRem(25)
  },
  importSuccess: {
    paddingBottom: theme.typography.pxToRem(25)
  }
});

class SummaryReport extends React.Component {
  renderReport = (importResults) => {
    const { classes, theme } = this.props;
    if (importResults.messages.length > 0) {
      var messageItems = importResults.messages;
      return (
        <Fragment>
          <Typography className={classes.importErrorTitle} variant="h6">
            Import Summary
          </Typography>
          
          <ul className={classes.messagesNotesList}>
            {messageItems.map((messageItem, index) => (
              <li key={index} className={messageItem.header ? classes.headingItem : classes.listItem}>
                {messageItem.error ? <strong>Error: </strong> : null}
                {messageItem.message}
              </li>
            ))}
          </ul>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Typography className={classes.importSuccess} variant="h6">
            Import Successful!
          </Typography>
        </Fragment>
      );
    }
  };

  render() {
    const { classes, importResults } = this.props;

    return (
      <Grid container className={classes.summaryReportContainer} spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.summaryReport}>
            {this.renderReport(importResults)}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(SummaryReport, styles, { withTheme: true });
