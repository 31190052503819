import React from 'react';
import { createRoot } from 'react-dom/client';
import LoginApp from './components/LoginApp';
import ForgotPasswordApp from './components/ForgotPasswordApp';
import ChangePasswordApp from './components/ChangePasswordApp';
import InfoApp from './components/InfoApp';
import SSOLoginApp from './components/SSOLoginApp';

const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

const searchParams = new URLSearchParams(window.location.search);

const view = document.getElementById('login_app');
if (view) {
  const root = createRoot(view);
  if (searchParams.has('defaultEmail')) {
    root.render(<LoginApp csrf={csrf} flashMessages={window.flash_messages || []} {...(view.dataset)} />);
  } else {
    root.render(<SSOLoginApp csrf={csrf} flashMessages={window.flash_messages || []} {...(view.dataset)} />);
  }
}

const forgotApp = document.getElementById('forgot_app');
if (forgotApp) {
  const root = createRoot(forgotApp);
  root.render(<ForgotPasswordApp csrf={csrf} validationErrors={window.validation_errors} flashMessages={window.flash_messages || []} {...(forgotApp.dataset)} />);
}

const changePasswordApp = document.getElementById('change_password_app');
if (changePasswordApp) {
  const root = createRoot(changePasswordApp);
  root.render(<ChangePasswordApp csrf={csrf} validationErrors={window.validation_errors} flashMessages={window.flash_messages || []} {...(changePasswordApp.dataset)} />);
}

const infoApp = document.getElementById('info_app');
if (infoApp) {
  const root = createRoot(infoApp);
  root.render(<InfoApp message="Your password has been reset. To continue please login to the Educator Passport app on your device."/>);
}
