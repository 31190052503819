import React from 'react';
import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import StyledDivider from '../../../common/components/misc/StyledDivider';

const styles = (theme) => ({
  defaultTabText: {
    color: theme.typography.color.primary,
    opacity: 0.55
  },
  reportsHeadline: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8)
  },
  selectedTabText: {
    opacity: 1
  },
  typographyTabText: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform:"none"
  }
});

class VcmCourses extends React.Component {
  constructor(props) {
    super(props);
  }


  makeTab = (course) => {
    const { classes } = this.props;
    return (
      <Tab
        key={course.id}
        value={course.id}
        classes={{
          root: classes.defaultTabText,
          selected: classes.selectedTabText
        }}
        label={
          <Typography className={classes.typographyTabText}>
            {course.code}
          </Typography>
        }
      />
    );
  };

  render() {
    const { courses, selectedCourseId, onTabChange } = this.props;

    const tabs = courses.map((course) => {
      return this.makeTab(course);
    });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs
            onChange={onTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            value={selectedCourseId}
            variant='scrollable'
            scrollButtons="auto"
          >
            {tabs}
          </Tabs>
          <StyledDivider />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(VcmCourses, styles, { withTheme: true });
