import React from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import VcmUnitItem from './VcmUnitItem';
import SearchIcon from '@common/icons/SearchIcon';

const VcmUnitPicker = ({ vcmUnits, label, placeholder, value = [], error, onChange }) => {

  const options = vcmUnits.filter(
    ({ code }) => !value.find((v) => v === code)
  );

  return (
    <Stack sx={{ width: '100%', gap: 2, mb: 4 }}>
      <Stack sx={{ gap: '4px' }}>
        {value.map((unitCode) => (
          <VcmUnitItem
            key={unitCode}
            item={unitCode}
            onDelete={() => onChange?.(value.filter((v) => v !== unitCode))}
          />
        ))}
      </Stack>

      <Autocomplete
        key={value}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            paddingTop: '12px',
            paddingBottom: '0px'
          }
        }}
        slotProps={{
          paper: {
            sx: {
              '& .MuiAutocomplete-listbox': {
                '& .MuiAutocomplete-option': {
                  '&:before': {
                    content: '""',
                    p: 0
                  }
                }
              }
            }
          }
        }}
        onChange={(_, newValue, reason) => {
          if (reason === 'selectOption') {
            onChange?.(value.concat(newValue.code));
          }
        }}
        fullWidth
        options={options}
        renderInput={(params) => (
          <TextField
            variant="standard"
            label={label}
            placeholder={placeholder}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />
            }}
            helperText={error}
            error={!!error}
          />
        )}
        isOptionEqualToValue={(option, value) => option.code === value.code}
      />
    </Stack>
  );
};

export default VcmUnitPicker;
