import React, { Fragment, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { vcmUnitResponseStatusOptions } from './options';

const VcmSummary = (props) => {
  const { classes, vcm, unitResponses } = props;

  const counts = useMemo(() => {
    return Object.keys(vcmUnitResponseStatusOptions)
      .map((status) => ({
        status,
        count: unitResponses.filter((unit) => unit.status === status).length
      }))
      .filter(({ count }) => count > 0);
  }, [unitResponses]);

  if (!vcm) {
    return null;
  }

  return (
    <Fragment>
      <div className={classes.unitsCountsContainer}>
        <Typography color="primary">
          Units Total: {unitResponses.length}
        </Typography>
        {counts.map(({ status, count }) => (
          <Typography key={status} color="primary">
            {vcmUnitResponseStatusOptions[status]?.label ?? status}: {count}
          </Typography>
        ))}
      </div>

      <Typography color="primary">Revision {vcm.revision_text}</Typography>
      <Typography color="primary">Approver: {vcm.approver_user?.full_name ?? "UNKNOWN"}</Typography>

    </Fragment>
  );
};

export default VcmSummary;
