import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { unitResponsesStyles } from './styles';

const VcmCourseRemovedUnits = ({ classes, units }) => {
  if (!units?.length) {
    return null;
  }

  return (
    <div className={classes.paddedContainer}>
      <Typography className={classes.smallLabel}>
        Units the educator has removed from this course:
      </Typography>
      {units.map((unit) => (
        <Typography key={unit.id} className={classes.body2}>
          {unit.code}
        </Typography>
      ))}
    </div>
  );
};

export default withStyles(VcmCourseRemovedUnits, unitResponsesStyles, {
  withTheme: true
});
