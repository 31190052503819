import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { Button, Paper, Typography, Grid } from '@mui/material';
import ApiService from '@common/services/ApiService';
import EntityManage from '@common/components/EntityManage';
import AdminTable from '@common/components/table/AdminTable';
import { vcmStatusOptions } from './options';
import ErrorToast from './ErrorToast';

const FIELDS = [
  'created_at',
  'description',
  'user.full_name',
  'educator_visible'
];

const METADATA = {
  created_at: {
    type: 'datetime',
    label: 'Date & Time'
  },
  description: {
    type: 'string',
    label: 'Event'
  },
  'user.full_name': {
    type: 'string',
    label: 'User'
  },
  educator_visible: {
    type: 'yes_no',
    label: 'Educator Visible'
  }
};

const styles = (theme) => ({
  container: {
    marginTop: theme.typography.pxToRem(25)
  },
  textButton: {
    textTransform: 'none'
  }
});

class VcmHistory extends EntityManage {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      vcmId: props.match.params.id,
      errorMessage: null,
      vcm: null
    };
  }

  componentDidMount() {
    this.getVcmData();
  }

  getVcmData = async () => {
    try {
      const vcm = await this.api.get('vcms', this.state.vcmId);
      this.setState({
        vcm: vcm.json.data
      });
    } catch (error) {
      this.setState({
        errorMessage: 'Something went wrong, please try again'
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { vcm, errorMessage } = this.state;

    if (!vcm) {
      return null;
    }

    const vcmStatusText = vcmStatusOptions[vcm.status]?.label ?? vcm.status;
    return (
      <>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.vcmsListHeadline} variant="h5">
              VCM History
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button
              component={Link}
              to={`/vcm/${vcm.id}`}
              className={classes.textButton}
            >
              Return to VCM
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary">
              Educator: {vcm.owner_user.full_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary">
              Revision {vcm.revision_text}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <AdminTable
                showTablePagination
                metadata={METADATA}
                fields={FIELDS}
                resource={`vcms/${vcm.id}/history_logs`}
              />
            </Paper>
          </Grid>
        </Grid>
        <ErrorToast
          open={!!errorMessage}
          errorMessage={errorMessage}
          onClose={() => this.setState({ errorMessage: null })}
        />
      </>
    );
  }
}

export default withStyles(VcmHistory, styles, { withTheme: true });
