import React, { Fragment, useMemo, useCallback, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AdminTable from '@common/components/table/AdminTable';
import ApiService from '@common/services/ApiService';
import { unitResponsesStyles } from './styles';
import VcmUnitResponseDetailCell from './VcmUnitResponseDetail';
import VcmUnitResponseStatus from './VcmUnitResponseStatus';
import UnitResponseRejectModal from './UnitResponseRejectModal';
import ErrorToast from './ErrorToast';
import { vcmStatusOptions } from './options';
import UnitResponseUpdateModal from './UnitResponseUpdateModal';

const fields = ['unit.code', 'detail', 'status'];

const api = new ApiService();
const VcmUnitResponses = ({
  vcm,
  refreshTick,
  onRefresh,
  filters,
  params,
  classes,
  institute
}) => {
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [unitResponseForModal, setUnitResponseForModal] = useState(undefined);
  const [isRejectModalOpened, setIsRejectModalOpened] = useState(false);
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false);

  const openRejectModal = useCallback(
    (unitResponse) => {
      setUnitResponseForModal(unitResponse);
      setIsRejectModalOpened(true);
    },
    [setUnitResponseForModal, setIsRejectModalOpened]
  );

  const openUpdateModal = useCallback(
    (unitResponse) => {
      setUnitResponseForModal(unitResponse);
      setIsUpdateModalOpened(true);
    },
    [setUnitResponseForModal, setIsUpdateModalOpened]
  );

  const handleCloseRejectModal = useCallback(() => {
    setUnitResponseForModal(undefined);
    setIsRejectModalOpened(false);
  }, [setIsRejectModalOpened, setUnitResponseForModal]);

  const handleCloseUpdateModal = useCallback(() => {
    setUnitResponseForModal(undefined);
    setIsUpdateModalOpened(false);
  }, [setIsUpdateModalOpened, setUnitResponseForModal]);

  const metadata = useMemo(
    () => ({
      'unit.code': {
        type: 'string',
        label: 'Unit',
        tableCellProps: { className: classes.tableCell }
      },
      detail: {
        type: 'component',
        label: 'Details',
        component: VcmUnitResponseDetailCell,
        componentProps: { vcm, onEdit: openUpdateModal, institute },
        tableCellProps: { className: classes.tableCell }
      },
      status: {
        type: 'component',
        label: 'Status',
        component: VcmUnitResponseStatus,
        tableCellProps: { className: classes.tableCell }
      }
    }),
    [classes.tableCell, vcm, institute, openUpdateModal]
  );

  const handleApprove = useCallback(async (unitResponse) => {
    setIsPending(true);

    try {
      const url = `/api/v1/vcms/${unitResponse.vcm_id}/unit_responses/${unitResponse.unit.id}/approve`;
      const res = await api.put(url);
      if (res.status >= 200 && res.status < 400) {
        onRefresh();
      } else {
        setErrorMessage(res.json.message);
      }
    } catch (error) {
      setErrorMessage('Something went wrong, please try again');
    } finally {
      setIsPending(false);
    }
  }, []);

  const ApproveButton = ({ unitResponse }) => {
    return (
      <Button
        disabled={isPending || vcm.status !== vcmStatusOptions.in_review.id}
        onClick={() => {
          handleApprove(unitResponse);
        }}
        className={`${classes.actionButtons} ${classes.approveButton}`}
        color="secondary"
        size="small"
        variant="contained"
      >
        <Typography variant="button">Approve</Typography>
      </Button>
    );
  };

  const RejectButton = ({ unitResponse }) => {
    return (
      <Button
        disabled={isPending || vcm.status !== vcmStatusOptions.in_review.id}
        onClick={() => {
          openRejectModal(unitResponse);
        }}
        className={`${classes.actionButtons} ${classes.rejectButton}`}
        color="secondary"
        size="small"
        variant="contained"
      >
        <Typography variant="button">Mark For Attention</Typography>
      </Button>
    );
  };

  const renderButtonActions = useCallback((unitResponse) => {
    if (!unitResponse || unitResponse.removed) {
      return null;
    }

    return (
      <>
        <ApproveButton unitResponse={unitResponse} />
        <RejectButton unitResponse={unitResponse} />
      </>
    );
  }, []);

  return (
    <>
      <AdminTable
        tableBottomPadding
        actionsSection={['buttons']}
        renderButtonActions={renderButtonActions}
        fields={fields}
        filters={filters}
        params={params}
        metadata={metadata}
        resource={`vcms/${vcm.id}/unit_responses`}
        actionCellProps={{
          className: classes.tableCell,
          width: 300
        }}
        refreshTick={refreshTick}
      />
      <UnitResponseRejectModal
        isOpened={isRejectModalOpened}
        onClose={handleCloseRejectModal}
        unitResponse={unitResponseForModal}
        onRefresh={onRefresh}
      />
      <UnitResponseUpdateModal
        vcm={vcm}
        isOpened={isUpdateModalOpened}
        onClose={handleCloseUpdateModal}
        unitResponse={unitResponseForModal}
        onRefresh={onRefresh}
      />
      <ErrorToast
        open={!!errorMessage}
        errorMessage={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
};

export default withStyles(VcmUnitResponses, unitResponsesStyles, {
  withTheme: true
});
