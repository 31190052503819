import format from 'date-fns/format';

const arrayToObject = (arr, keyField) =>
    Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));
  
  export const getCurrentYear = () => {
    return format(new Date().setFullYear(new Date().getFullYear()), 'yyyy');
  }
  
  export const createYearRange = () => {
    const numYears = 2;
    var yearsArr = [];
    var y = format(
      new Date().setFullYear(new Date().getFullYear() + 1),
      'yyyy'
    );
    for (var i = 0; i < numYears; i++) {
      yearsArr.push({ id: y - i, label: y - i });
    }
    return arrayToObject(yearsArr, 'id');
  };