import React from 'react';
import { Alert, Snackbar } from "@mui/material";

const ErrorToast = (props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={props.open}
      autoHideDuration={5000}
      onClose={props.onClose}
    >
      <Alert onClose={props.onClose} severity="error">
        {props.errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorToast;
