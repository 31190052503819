export const defaultModalStyles = theme => ({
  modalClose: {
    color: theme.typography.color.primary,
    cursor: 'pointer',
    opacity: 0.3
  },
  modalContentContainer: {
    backgroundColor: theme.palette.background.paper,
    left: '50%',
    margin: '0',
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(29)}`,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%', 
    maxHeight: '90%',
    overflow: 'auto'
  },
  modalHeader: {
    marginBottom: theme.typography.pxToRem(22)
  },
  wideModalContentContainer: {
    width: '80%',
  }
});
