import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import ApiService from '@common/services/ApiService';
import EntityManage from '@common/components/EntityManage';
import VcmSummary from './VcmSummary';
import VcmUnitsFilter from './VcmUnitsFilter';
import { Button, Paper, Typography } from '@mui/material';
import VcmCourses from './VcmCourses';
import VcmUnitResponses from './VcmUnitResponses';
import VcmProfessionalQualifications from './VcmProfessionalQualifications';
import VcmCourseRemovedUnits from './VcmCourseRemovedUnits';
import { vcmStatusOptions, vcmUnitResponseStatusOptions } from './options';
import VcmRejectModal from './VcmRejectModal';
import ErrorToast from './ErrorToast';
import VcmApproveModal from './VcmApproveModal';
import VcmUpdateStatusModal from './VcmUpdateStatusModal';
import VcmDelegateApproverModal from './VcmDelegateApproverModal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StyledDivider from '../../../common/components/misc/StyledDivider';

const SINGLE_UNIT_ID = 'single-unit';
const FILTER_STATUS_LIST = [
  vcmUnitResponseStatusOptions.in_review.id,
  vcmUnitResponseStatusOptions.approved.id,
  vcmUnitResponseStatusOptions.needs_attention.id,
  'previously_approved'
];

const INITIAL_FILTERS = {
  removed_eq: false,
  extended_status_in: [vcmUnitResponseStatusOptions.in_review.id]
};

const styles = (theme) => ({
  defaultTabText: {
    color: theme.typography.color.primary,
    opacity: 0.55
  },
  selectedTabText: {
    opacity: 1
  },
  typographyTabText: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    textTransform: 'none'
  },
  vcmDetailsContainer: {
    marginTop: theme.typography.pxToRem(25)
  },
  summaryHeadline: {
    marginBottom: theme.typography.pxToRem(16)
  },
  vcmStatus: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.33,
    textTransform: 'uppercase'
  },
  unitsCountsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
  },
  textButton: {
    textTransform: 'none'
  }
});

class VcmDetails extends EntityManage {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      vcmId: props.match.params.id,
      errorMessage: null,
      vcm: null,
      vcmCourses: [],
      courses: [],
      allUnitResponses: [],
      existingUnitResponses: [],
      allVcmProfessionalQualifications: [],
      removedUnits: [],
      selectedCourseId: null,
      filters: INITIAL_FILTERS,
      params: {},
      refreshTick: 0,
      isRejectModalOpened: false,
      isApproveModalOpened: false,
      isUpdateStatusModalOpened: false,
      isDelegateApproverModalOpened: false,
      activeTab: 'professional-qualifications'
    };
  }

  componentDidMount() {
    this.getVcmDetailsData();
  }

  getVcmDetailsData = async () => {
    try {
      const getVcmPromise = this.api.get('vcms', this.state.vcmId);
      const getUnitResponsesPromise = this.api.get(
        'vcms',
        this.state.vcmId + '/unit_responses'
      );
      const getVcmCoursesPromise = this.api.get(
        'vcms',
        this.state.vcmId + '/courses'
      );
      const getVcmProfessionalQualificationsPromise = this.api.get(
        'vcms',
        this.state.vcmId + '/professional_qualifications'
      );
      const [vcm, unitResponses, vcmCourses, vcmProfessionalQualifications] =
        await Promise.all([
          getVcmPromise,
          getUnitResponsesPromise,
          getVcmCoursesPromise,
          getVcmProfessionalQualificationsPromise
        ]);

      const coursesAndSingleUnit = vcmCourses.json.data
        .map(({ course }) => course)
        .concat({ id: SINGLE_UNIT_ID, code: 'Single Unit' });

      const allVcmProfessionalQualifications =
        vcmProfessionalQualifications.json.data;
      const selectedCourseId =
        this.state.selectedCourseId || coursesAndSingleUnit[0].id;
      const allUnitResponses = unitResponses.json.data;
      const existingUnitResponses = allUnitResponses.filter(
        (unitResponse) => !unitResponse.removed
      );
      const removedUnits = this.getRemovedUnits({
        selectedCourseId,
        vcmCourses: vcmCourses.json.data,
        allUnitResponses
      });
      this.setState({
        vcm: vcm.json.data,
        vcmCourses: vcmCourses.json.data,
        courses: coursesAndSingleUnit,
        allUnitResponses,
        allVcmProfessionalQualifications,
        removedUnits,
        existingUnitResponses,
        selectedCourseId,
        params: {
          course_id: selectedCourseId === SINGLE_UNIT_ID ? '' : selectedCourseId
        },
        refreshTick: Date.now()
      });
    } catch (error) {
      this.setState({
        errorMessage: 'Something went wrong, please try again'
      });
    }
  };

  getRemovedUnits = ({ selectedCourseId, vcmCourses, allUnitResponses }) => {
    const vcmCourse = vcmCourses.find(
      (vcmCourse) => vcmCourse.course.id === selectedCourseId
    );

    if (vcmCourse) {
      return allUnitResponses
        .filter(
          (unitResponse) =>
            unitResponse.removed &&
            vcmCourse.unit_ids?.includes(unitResponse.unit.id)
        )
        .map(({ unit }) => unit);
    }

    return [];
  };

  handleTabChange = (_, newValue) => {
    this.setState({ activeTab: newValue });
  };

  handleCourseTabChange = async (_, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        course_id: newValue === SINGLE_UNIT_ID ? '' : newValue
      }
    });
    const removedUnits = await this.getRemovedUnits({
      selectedCourseId: newValue,
      allUnitResponses: this.state.allUnitResponses,
      vcmCourses: this.state.vcmCourses
    });
    this.setState((currentState) => ({
      ...currentState,
      selectedCourseId: newValue,
      removedUnits
    }));
  };

  transformFilters = (filters) => {
    return FILTER_STATUS_LIST.reduce((accumulator, key) => {
      accumulator = {
        ...accumulator,
        [key]: filters.extended_status_in.includes(key)
      };
      return accumulator;
    }, {});
  };

  handleApplyFilter = (filters) => {
    const enabledFilters = Object.keys(filters).filter((key) => filters[key]);

    this.setState({
      filters: {
        ...this.state.filters,
        extended_status_in: enabledFilters
      }
    });
  };

  handleRefresh = () => {
    this.getVcmDetailsData();
  };

  processResponse(res) {
    // Calls the default
    super.processResponse(res);

    // Custom stuff
    if (res.status == 201) {
      this.props.history.push('/vcm/' + res.json.data.id);
    }
  }

  openRejectModal = () => {
    this.setState({ isRejectModalOpened: true });
  };

  handleCloseRejectModal = () => {
    this.setState({ isRejectModalOpened: false });
  };

  openApproveModal = () => {
    this.setState({ isApproveModalOpened: true });
  };

  handleCloseApproveModal = () => {
    this.setState({ isApproveModalOpened: false });
  };

  openUpdateStatusModal = () => {
    this.setState({ isUpdateStatusModalOpened: true });
  };

  handleCloseUpdateStatusModal = () => {
    this.setState({ isUpdateStatusModalOpened: false });
  };

  openDelegateApproverModal = () => {
    this.setState({ isDelegateApproverModalOpened: true });
  };

  handleCloseDelegateApproverModal = () => {
    this.setState({ isDelegateApproverModalOpened: false });
  };

  render() {
    const { classes } = this.props;
    const {
      vcm,
      filters,
      params,
      existingUnitResponses,
      allVcmProfessionalQualifications,
      courses,
      selectedCourseId,
      refreshTick,
      removedUnits,
      isRejectModalOpened,
      isApproveModalOpened,
      isUpdateStatusModalOpened,
      isDelegateApproverModalOpened,
      errorMessage,
      activeTab
    } = this.state;

    if (!vcm) {
      return null;
    }

    let canApprove = false;
    let canReject = false;
    if (
      existingUnitResponses.every(
        (unitResponse) =>
          unitResponse.status !== 'in_review' && vcm.status === 'in_review'
      ) &&
      allVcmProfessionalQualifications.every(
        (vcmProfessionalQualification) =>
          vcmProfessionalQualification.status !== 'in_review' &&
          vcm.status === 'in_review'
      )
    ) {
      canApprove =
        existingUnitResponses.every(
          (unitResponse) => unitResponse.status === 'approved'
        ) &&
        allVcmProfessionalQualifications.every(
          (vcmProfessionalQualification) =>
            vcmProfessionalQualification.status === 'approved'
        );
      canReject = !canApprove;
    }

    const vcmStatusText = vcmStatusOptions[vcm.status]?.label ?? vcm.status;
    return (
      <>
        <Grid container className={classes.vcmDetailsContainer} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.vcmStatus} color="primary">
              {vcmStatusText}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.summaryHeadline} variant="h5">
              VCM - {vcm.owner_user.full_name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
            gap={3}
          >
            <div>
              <Button
                className={classes.textButton}
                onClick={this.openDelegateApproverModal}
              >
                Delegate Approver
              </Button>
              <Button
                className={classes.textButton}
                onClick={this.openUpdateStatusModal}
              >
                Update Status
              </Button>
              <Button
                component={Link}
                to={`/vcm/${vcm.id}/history`}
                className={classes.textButton}
              >
                History
              </Button>
            </div>
            <Button
              disabled={vcm.status !== 'in_review'}
              variant={canReject ? 'contained' : 'outlined'}
              onClick={this.openRejectModal}
            >
              Return VCM To Educator
            </Button>
            <Button
              disabled={vcm.status !== 'in_review'}
              variant={canApprove ? 'contained' : 'outlined'}
              onClick={this.openApproveModal}
            >
              Approve VCM
            </Button>
          </Grid>
          <Grid item xs={12} my={2}>
            <Tabs
              onChange={this.handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              value={activeTab}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                value="professional-qualifications"
                classes={{
                  root: classes.defaultTabText,
                  selected: classes.selectedTabText
                }}
                label={
                  <Typography className={classes.typographyTabText}>
                    Professional Qualifications
                  </Typography>
                }
              />
              <Tab
                value="courses"
                classes={{
                  root: classes.defaultTabText,
                  selected: classes.selectedTabText
                }}
                label={
                  <Typography className={classes.typographyTabText}>
                    Courses
                  </Typography>
                }
              />
            </Tabs>
            <StyledDivider />
          </Grid>
          {activeTab === 'professional-qualifications' ? (
            <>
              <Grid item xs={12}>
                <Paper>
                  <VcmProfessionalQualifications
                    vcm={vcm}
                    institute={this.props.institute}
                    refreshTick={refreshTick}
                    onRefresh={this.handleRefresh}
                  />
                </Paper>
              </Grid>
            </>
          ) : null}
          {activeTab === 'courses' ? (
            <>
              <Grid item xs={12}>
                <VcmSummary
                  vcm={vcm}
                  classes={classes}
                  unitResponses={existingUnitResponses}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <VcmUnitsFilter
                    initialFilters={this.transformFilters(INITIAL_FILTERS)}
                    onApplyFilters={this.handleApplyFilter}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} mt={2}>
                <VcmCourses
                  courses={courses}
                  onTabChange={this.handleCourseTabChange}
                  selectedCourseId={selectedCourseId}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <VcmUnitResponses
                    vcm={vcm}
                    institute={this.props.institute}
                    refreshTick={refreshTick}
                    filters={filters}
                    params={params}
                    onRefresh={this.handleRefresh}
                  />
                  <VcmCourseRemovedUnits units={removedUnits} />
                </Paper>
              </Grid>
            </>
          ) : null}
        </Grid>
        <VcmRejectModal
          isOpened={isRejectModalOpened}
          onClose={this.handleCloseRejectModal}
          unitResponses={existingUnitResponses}
          vcmProfessionalQualifications={allVcmProfessionalQualifications}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <VcmApproveModal
          isOpened={isApproveModalOpened}
          onClose={this.handleCloseApproveModal}
          unitResponses={existingUnitResponses}
          vcmProfessionalQualifications={allVcmProfessionalQualifications}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <VcmUpdateStatusModal
          isOpened={isUpdateStatusModalOpened}
          onClose={this.handleCloseUpdateStatusModal}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <VcmDelegateApproverModal
          isOpened={isDelegateApproverModalOpened}
          onClose={this.handleCloseDelegateApproverModal}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <ErrorToast
          open={!!errorMessage}
          errorMessage={errorMessage}
          onClose={() => this.setState({ errorMessage: null })}
        />
      </>
    );
  }
}

export default withStyles(VcmDetails, styles, { withTheme: true });
