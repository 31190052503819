import React from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ApiService from '../../../../common/services/ApiService';
import SummaryReport from './SummaryReport';
import ImportForm from './ImportForm';
import styles from './styles'
import {getCurrentYear, createYearRange} from './utils'

class VcmManagerProfessionalQualifications extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      deactivateUsers: false,
      file: null,
      importBtn: false,
      importResults: null,
      ranImport: false,
      errorMessage: null,
      isUploading: false,
      year: getCurrentYear(),
      yearsRange: createYearRange()
    };
  }

  handleRunImport = () => {
    this.setState({ importBtn: false, isUploading: true });
    this.api
      .upload('/api/v1/professional_qualifications/import', this.state.file, 'import[file]', {
        'import[year]': this.state.year
      })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.setState({
          importResults: json.data,
          ranImport: true,
          importBtn: true,
          isUploading: false
        });
      })
      .catch((e) => {
        this.setState({
          errorMessage: e.message,
          ranImport: false,
          importBtn: true,
          isUploading: false
        });
      });
  };

  handleExport = () => {
    const { year } = this.state;
    const searchRequest = this.api.searchUrl(null, { year }, null, {}, null);
    return '/api/v1/professional_qualifications.csv?' + searchRequest;
  };

  onCsvFileChange = (file) => {
    this.setState({ file: file, importBtn: true });
  };

  onYearChange = (e) => {
    this.setState({ year: e.target.value });
  };

  render() {
    const { classes, theme } = this.props;
    const {
      file,
      importBtn,
      importResults,
      ranImport,
      errorMessage,
      isUploading,
      year,
      yearsRange
    } = this.state;

    if (ranImport === true) {
      return <SummaryReport importResults={importResults} />;
    }

    return (
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Typography variant="h5">Manage Professional Qualifications</Typography>
            <Grid item>
              <Grid container>
                <Grid item>
                  <div className={classes.buttonProgressWrapper}>
                    <Button
                      component={'a'}
                      href={this.handleExport()}
                      target="_blank"
                      color="secondary"
                      size="small"
                      variant="contained"
                    >
                      <Typography variant="button">Export</Typography>
                    </Button>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.buttonProgressWrapper}>
                    <Button
                      color="secondary"
                      disabled={!importBtn || isUploading}
                      onClick={this.handleRunImport}
                      size="small"
                      variant="contained"
                    >
                      <Typography variant="button">Import</Typography>
                    </Button>
                    {isUploading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isUploading && (
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Typography color="textSecondary">
                Processing large imports could take several minutes
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ImportForm
            file={file}
            onCsvFileChange={this.onCsvFileChange}
            year={year}
            yearsRange={yearsRange}
            onYearChange={this.onYearChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.importInstructions}>
            <Typography className={classes.notesHeaders} variant="h6">
              Instructions
            </Typography>

            <Typography className={classes.notesBody}>
              This CSV import can be used to import professional qualifications used for
              educator VCMs.
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                If first exporting a CSV file from a spreadsheet such as Microsoft Excel,
                the file should be exported as a "UTF-8 CSV" file to ensure all data is preserved
                correctly. Otherwise, this importer may report a damaged CSV file and refuse
                to make any changes.
              </li>
              <li className={classes.listItem}>
                Please select the effective year to upload the professional qualification
                list for. This will make the professional qualifications codes available to
                VCMs created for the selected year.
              </li>
              <li className={classes.listItem}>
                Use the EXPORT button to download the current data for the
                selected effective year. This can also be used to get a template for
                a new year.
              </li>
              <li className={classes.listItem}>
                When a new CSV file is uploaded it adds new professional qualification data for the
                selected effective year, or updates the data if there has already
                been an upload for that year.
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    New professional qualification codes will get created in the system
                  </li>
                  <li className={classes.listItem}>
                    Professional qualifications titles will get updated for the relevant code
                  </li>
                  <li className={classes.listItem}>
                    Professional qualifications missing from the uploaded file get
                    deactivated from the list, i.e. You must upload the full
                    list for the effective year each time
                  </li>
                  <li className={classes.listItem}>
                    If a deactivated professional qualification reappears in a later import,
                    then it will get reactivated
                  </li>
                </ul>
              </li>
              <li className={classes.listItem}>
                The first row of the CSV file must contain the following column
                headers:
                <ul className={classes.list}>
                  <li className={classes.listItem}>Qualification Code</li>
                  <li className={classes.listItem}>Qualification Title</li>
                </ul>
              </li>
              <li className={classes.listItem}>All of the above columns are mandatory,
                and any additional columns will be ignored.</li>
              <li className={classes.listItem}>
                When there are problems importing the data, the importer may report
                that these need to be fixed before the import will work. It will
                give you error messages and the row numbers in the CSV file where the
                problem occurs. It will not make any changes to the system in this case,
                even for rows that did not have problems.
              </li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(VcmManagerProfessionalQualifications, styles, { withTheme: true });
