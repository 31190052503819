import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { InstituteActions } from './InstituteActions';
import InstituteDetails from './InstituteDetails';
import Locations from './Locations';
import Divisions from './Divisions';
import Domains from './Domains';
import ProfilingToolLabels from './ProfilingToolLabels';
import EmploymentTypes from './EmploymentTypes';
import VocationalActivities from './VocationalActivities';
import Banners from './Banners';
import VideoResources from './VideoResources';
import ApiService from '@common/services/ApiService';
import EntityManage from '@common/components/EntityManage';
import StyledDivider from '@common/components/misc/StyledDivider';
import { Notification } from './Notification';
import { manageInstituteStyles } from './styles';
import Categories from './Categories';
import Vcm from './Vcm';

class ManageInstitute extends EntityManage {
  api = new ApiService();

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dirty: false,
      errorMessage: null,
      instituteDetails: null,
      instituteLogo: null,
      tabValue: null,
      timezoneOptions: [],
      validationErrors: [],
      instituteSections: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getInstituteSections(instituteDetails) {
    const {
      user: { is_institute_admin, is_super_admin }
    } = this.props;

    if (!is_institute_admin && !is_super_admin) {
      return [];
    }

    const result = [
      ['institute_details', 'Institute Details'],
      ['locations', 'Locations'],
      ['divisions', 'Divisions'],
      ['categories', 'Program Categories'],
      ['domains', 'Domains'],
      ['profiling_tool_labels', 'Profiling Tool Labels'],
      ['employment_types', 'Employment Types, Passport Requirements & Enabled Features'],
      ['vocational_activities', 'Vocational Activities'],
      ['banners', 'Banners'],
      ['video_resources', 'Video Resources'],
      ['notification', 'Notification']
    ];

    if (instituteDetails?.vcm_enabled) {
      result.push(['vcm', 'VCM']);
    }

    return result;
  }

  convertTimezonesObject = (data) => {
    var timezoneOptions = {};
    var count = 0;
    Object.keys(data).map((obj) => {
      Object.assign(timezoneOptions, { [obj]: { id: obj, label: data[obj] } });
    });
    this.setState({ timezoneOptions: timezoneOptions });
  };

  getData = () => {
    const getInstitutePromise = this.api.get(
      'institutes',
      this.props.institute.id
    );
    const timezonesOptionsPromise = this.api.query('/api/v1/timezones/options');

    Promise.all([getInstitutePromise, timezonesOptionsPromise])
      .then(([res, timezonesOptions]) => {
        if (res.status === 200) {
          this.convertTimezonesObject(timezonesOptions.data);
          this.setEntity(res.json);
        } else {
          this.setState({
            errorMessage: 'Something went wrong. got status=' + res.status
          });
        }
      })
      .catch(this.processError);
  };

  handleInstituteChange = (newInstitute) => {
    this.setState({ dirty: true, instituteDetails: newInstitute });
  };

  handleLogoChange = (logo) => {
    let instituteDetails = { ...this.state.instituteDetails };
    instituteDetails.logo_filename = logo.name;
    this.setState({
      dirty: true,
      instituteDetails: instituteDetails,
      instituteLogo: logo
    });
  };

  handleTabChange = (event, tabValue) => {
    this.props.history.push(
      `/institutes/${this.props.institute.id}/${this.state.instituteSections[tabValue][0]}`
    );
  };

  makeTab = (section) => {
    const { classes } = this.props;

    return (
      <Tab
        key={section[0]}
        classes={{
          root: classes.defaultTabText,
          selected: classes.selectedTabText
        }}
        label={
          <Typography className={classes.typographyTabText}>
            {section[1]}
          </Typography>
        }
      />
    );
  };

  processResponse(res) {
    super.processResponse(res);
    if (res.status === 200) {
      if (this.state.instituteLogo != null) {
        this.uploadLogo();
      }
      // reload the page after changes saved
      window.location.reload();
    }
  }

  saveData = () => {
    this.api
      .saveResource('institute', {
        ...this.state.instituteDetails
      })
      .then((res) => {
        this.processResponse(res);
      })
      .catch((ex) => {
        this.processError(ex);
      });
  };

  selectTab = (instituteSection, instituteSections) => {
    var index = instituteSections.findIndex(
      (section) => instituteSection === section[0]
    );
    if (index < 0 || index >= instituteSections.length) {
      console.warn('Not valid institute section: ' + instituteSection);
      index = false;
    }
    return index;
  };

  setEntity = (json) => {
    const s = { validationErrors: [], dirty: false, errorMessage: null };
    const instituteSections = this.getInstituteSections(json.data);
    this.setState({
      ...s,
      instituteDetails: json.data,
      loading: false,
      instituteSections,
      tabValue: this.selectTab(
        this.props.match.params.section,
        instituteSections
      )
    });
  };

  uploadLogo = () => {
    let instituteDetails = { ...this.state.instituteDetails };
    instituteDetails.logo_filename = this.state.instituteLogo.name;
    this.setState({ instituteDetails: instituteDetails });

    const url = '/api/v1/institutes/' + this.props.institute.id + '/logo';
    this.api
      .upload(url, this.state.instituteLogo, 'institute[logo]')
      .then((response) => {
        this.getData();
      })
      .catch((ex) => console.error(ex));
  };

  render() {
    const {
      classes,
      user,
      match: { params }
    } = this.props;

    const {
      dirty,
      errorMessage,
      instituteDetails,
      tabValue,
      timezoneOptions,
      validationErrors
    } = this.state;

    const instituteDetailsTab = ['institute_details', 'notification', 'vcm'].includes(
      params.section
    );

    const tabs = this.state.instituteSections.map((section) => {
      return this.makeTab(section);
    });

    let i = 1;

    if (this.state.loading) {
      return <CircularProgress />;
    }

    return (
      <Grid container className={classes.newInstituteContainer} spacing={2}>
        <Grid item xs={12}>
          {instituteDetails && (
            <InstituteActions
              dirty={dirty}
              errorMessage={errorMessage}
              instituteDetails={instituteDetails}
              instituteDetailsTab={instituteDetailsTab}
              onSave={this.saveData}
              styles={classes.createInstituteHeadline}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Tabs
            onChange={this.handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            value={tabValue}
            variant="scrollable"
          >
            {tabs}
          </Tabs>
          <StyledDivider />
        </Grid>
        <Grid item xs={12}>
          {instituteDetails && tabValue === 0 && (
            <InstituteDetails
              instituteDetails={instituteDetails}
              onInstituteChange={this.handleInstituteChange}
              onLogoChange={this.handleLogoChange}
              timezoneOptions={timezoneOptions}
              user={user}
              validationErrors={validationErrors}
            />
          )}
          {tabValue === i++ && <Locations />}
          {tabValue === i++ && <Divisions />}
          {tabValue === i++ && <Categories />}
          {tabValue === i++ && <Domains />}
          {tabValue === i++ && <ProfilingToolLabels />}
          {tabValue === i++ && (
            <EmploymentTypes instituteDetails={instituteDetails} />
          )}
          {tabValue === i++ && <VocationalActivities />}
          {tabValue === i++ && <Banners />}
          {tabValue === i++ && <VideoResources />}
          {tabValue === i++ && instituteDetails && (
            <Notification
              instituteDetails={instituteDetails}
              onChange={this.handleInstituteChange}
              validationErrors={validationErrors}
            />
          )}
          {tabValue === i++ &&
            instituteDetails && (
              <Vcm
                instituteDetails={instituteDetails}
                onChange={this.handleInstituteChange}
                validationErrors={validationErrors}
              />
            )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(ManageInstitute, manageInstituteStyles, {
  withTheme: true
});
