import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EntityManage from '@common/components/EntityManage';
import WaveSelectField from '@common/components/form/WaveSelectField';
import WaveTextArea from '@common/components/form/WaveTextArea';
import WaveTextField from '@common/components/form/WaveTextField';
import { vcManageStyles } from './styles';
import { DefaultButton } from '@common/components/default-button';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { formHelperHoc } from '@common/components/form-helper-hoc';
import WaveMultipleFileUploader from '@common/components/form/WaveMultipleFileUploader';
import Chisholm from '@common/config/Chisholm';
import { transformEvidences } from '@common/services/PointService';
import { MAX_FILE_SIZE } from '@common/constants';
import { WaveDatePicker } from '@common/components/WaveDatePicker';
import VcmUnitPicker from '../user-point-request/VcmUnitPicker';

const iniitalState = {
  vocational_activity_id: null,
  points: null,
  activity_name: '',
  activity_date: new Date(),
  activity_duration: undefined,
  activity_interval: undefined,
  inform_teaching_practice: '',
  related_to_units: '',
  student_benefit: '',
  evidences: [],
  points_history_units: [],
  notify_user: true
};

class VcManageModal extends EntityManage {
  constructor(props) {
    super(props);

    this.state = iniitalState;
  }

  handleInputChange = (event) => {
    const {
      target: { value, name }
    } = event;
    const { vocationalActivitiesOptions } = this.props;

    if (name === 'vocational_activity_id') {
      this.setState({ points: vocationalActivitiesOptions[value].points });
    }

    this.setState({ [name]: value });
  };

  handleDateChange = (name) => (date) => {
    this.setState((d) => ({
      ...d,
      [name]: date
    }));
  };

  handleArrayInputChange = (name) => (value) => {
    this.setState((currentState) => ({
      ...currentState,
      [name]: value
    }));
  };

  handlePush = ({ name, value }) => {
    this.setState((d) => ({
      ...d,
      [name]: d[name].concat(value)
    }));
  };

  handleRemove = ({ name, index }) => {
    this.setState((d) => {
      const array = d[name].slice();
      array.splice(index, 1);

      return {
        ...d,
        [name]: array
      };
    });
  };

  handleReplace = ({ index, name, value }) => {
    const array = data[name].slice();
    array.splice(index, 1, value);

    this.setState((d) => {
      const array = d[name].slice();
      array.splice(index, 1, value);

      return {
        ...d,
        [name]: array
      };
    });
  };

  resetModal = () => {
    this.setState(iniitalState);
  };

  handleModalClose = () => {
    const { onClose } = this.props;

    this.resetModal();

    onClose();
  };

  handleSubmit = async () => {
    const {
      points,
      vocational_activity_id,
      notify_user,
      activity_name,
      activity_date,
      activity_duration,
      activity_interval,
      inform_teaching_practice,
      related_to_units,
      student_benefit,
      evidences,
      points_history_units
    } = this.state;

    const { onSubmit } = this.props;

    let reset = await onSubmit({
      points,
      vocational_activity_id,
      notify_user,
      activity_name,
      activity_date,
      activity_duration,
      activity_interval,
      inform_teaching_practice,
      related_to_units,
      student_benefit,
      evidences_attributes: transformEvidences(evidences),
      points_history_units
    });

    if (reset) {
      this.resetModal();
    }
  };
  render() {
    const {
      classes,
      isOpened,
      pointsFileAttachmentEnabled,
      vocationalActivitiesOptions,
      activityIntervalOptions,
      errorMessage,
      isLoading,
      makeFieldProps,
      vcmUnits,
      vocationalCurrencyUsesUnits
    } = this.props;

    const {
      points,
      vocational_activity_id,
      notify_user,
      activity_name,
      activity_date,
      activity_duration,
      activity_interval,
      inform_teaching_practice,
      related_to_units,
      student_benefit,
      evidences,
      points_history_units
    } = this.state;

    return (
      <DefaultModal
        wide
        isOpened={isOpened}
        isLoading={isLoading}
        onClose={this.handleModalClose}
        title="Add External Vocational Currency"
        action={
          <Grid container justifyContent="flex-end">
            <DefaultButton
              containerClass={classes.addPointsButton}
              color="secondary"
              size="small"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Add
            </DefaultButton>
          </Grid>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
          <Grid item xs={4}>
            <WaveTextField
              label="Points"
              onChange={this.handleInputChange}
              {...makeFieldProps('points', points == 0 ? '' : points)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveSelectField
              label="Activity Type"
              menuItems={vocationalActivitiesOptions}
              onChange={this.handleInputChange}
              {...makeFieldProps(
                'vocational_activity_id',
                vocational_activity_id,
                'vocational_activity'
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveTextField
              label="Name of Activity"
              onChange={this.handleInputChange}
              {...makeFieldProps('activity_name', activity_name)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveDatePicker
              className={classes.ieFix}
              format={Chisholm.rangeDate}
              label="Date of Activity"
              onChange={this.handleDateChange('activity_date')}
              {...makeFieldProps('activity_date', activity_date)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveTextField
              label="Duration of Activity (number)"
              onChange={this.handleInputChange}
              {...makeFieldProps('activity_duration', activity_duration)}
            />
          </Grid>
          <Grid item xs={4}>
            <WaveSelectField
              label="Interval of Activity"
              menuItems={activityIntervalOptions}
              onChange={this.handleInputChange}
              {...makeFieldProps('activity_interval', activity_interval)}
            />
          </Grid>
          <Grid item xs={12}>
            {vocationalCurrencyUsesUnits ? (
              <VcmUnitPicker
                label="Related to Unit/s"
                placeholder="Search by unit code"
                vcmUnits={vcmUnits}
                {...makeFieldProps(
                  'points_history_units',
                  points_history_units
                )}
                onChange={this.handleArrayInputChange('points_history_units')}
              />
            ) : (
              <WaveTextArea
                label="Related to Unit/s"
                maxLength={1000}
                rows={4}
                onChange={this.handleInputChange}
                placeholder="Please list unit code/s and title/s with which this specific currency activity is relevant to."
                {...makeFieldProps('related_to_units', related_to_units)}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <WaveTextArea
              label="Inform Teaching and Assessment Practice"
              maxLength={1000}
              rows={4}
              onChange={this.handleInputChange}
              placeholder="Describe what you did, discussed or learnt in terms of new skills and knowledge, and how this will inform your teaching and assessment practices?"
              {...makeFieldProps(
                'inform_teaching_practice',
                inform_teaching_practice
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <WaveTextArea
              label="Student Benefit"
              maxLength={1000}
              rows={4}
              onChange={this.handleInputChange}
              placeholder="How will this activity impact/benefit your students?"
              {...makeFieldProps('student_benefit', student_benefit)}
            />
          </Grid>
          {pointsFileAttachmentEnabled && (
            <Grid item xs={12}>
              <WaveMultipleFileUploader
                label="Attach Evidence"
                accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                maxFileSize={MAX_FILE_SIZE}
                onPush={this.handlePush}
                onReplace={this.handleReplace}
                onRemove={this.handleRemove}
                {...makeFieldProps('evidences', evidences)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl variant="standard">
              <FormGroup row>
                <DefaultCheckbox
                  name="notify_user"
                  label="Notify the user that points have been added to their points history"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  value={notify_user}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DefaultModal>
    );
  }
}

const StyledVcManageModal = formHelperHoc(
  withStyles(VcManageModal, vcManageStyles, {
    withTheme: true
  })
);

export { StyledVcManageModal as VcManageModal };
