import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import WaveSelectField from '@common/components/form/WaveSelectField';
import { withStyles } from 'tss-react/mui';
import { unitResponsesStyles } from './styles';
import { vcmStatusOptions } from './options';

const api = new ApiService();
const VcmUpdateStatusModal = ({
  classes,
  isOpened,
  onClose,
  onRefresh,
  vcm
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [data, setData] = useState();

  useEffect(()=>{
    setData({
      status: vcm.status
    })
  },[vcm.status, isOpened])

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;

      setData({ ...data, [name]: value });
    },
    [data, setData]
  );

  const errorStatus = useCallback(
    (field) => {
      return validationErrors && validationErrors.hasOwnProperty(field);
    },
    [validationErrors]
  );

  const errorHelpers = useCallback(
    (field) => {
      return errorStatus(field) ? validationErrors[field][0] : null;
    },
    [errorStatus, validationErrors]
  );

  const makeFieldProps = useCallback(
    (field, fieldValue, errorField) => {
      return {
        error: errorStatus(errorField || field),
        helperText: errorHelpers(errorField || field),
        name: field,
        value: fieldValue,
      };
    },
    [errorStatus, errorHelpers]
  );

  const setErrors = useCallback(
    (json) => {
      if (json.code == 1) {
        setErrorMessage(json.message);
      } else if (json.code == 2) {
        setValidationErrors(json.messages);
      }
    },
    [setErrorMessage, setValidationErrors]
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const url = `/api/v1/vcms/${vcm.id}/force_update`;
      const res = await api.put(url, { vcm: data });

      if (res.status === 200) {
        handleClose();
        onRefresh();
      } else if (res.status === 422 || res.status === 400 || res.status === 403) {
        setErrors(res.json);
        setErrorMessage(res.json.message);
      } else {
        console.error('Unknown response', res);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [data]);

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    setValidationErrors([]);
    onClose();
  }, [setErrorMessage, setValidationErrors, onClose]);

  return (
    <DefaultModal
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={handleClose}
      title="Update status"
      action={
        <DefaultButton
          className={classes.button}
          color="secondary"
          onClick={handleSubmit}
          size="small"
          variant="contained"
        >
          Update
        </DefaultButton>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component='span' >
          Force a status change on <Typography component='span' className={classes.label}>{vcm.owner_user.full_name}</Typography>'s VCM
          </Typography>
        </Grid>
        <Grid item xs={12} mb={4}>
          <WaveSelectField
            noValidation
            label="Status"
            menuItems={vcmStatusOptions}
            {...makeFieldProps('status', data?.status)}
            onChange = {handleInputChange}
          />
        </Grid>
      </Grid>
    </DefaultModal>
  );
};

export default withStyles(VcmUpdateStatusModal, unitResponsesStyles, {
  withTheme: true
});
