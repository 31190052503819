import React, { Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ApiService from '../../../common/services/ApiService';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const styles = (theme) => ({
  checkboxField: {
    marginRight: theme.typography.pxToRem(38)
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(12)
  },
  clearFilters: {
    color: theme.typography.color.primary,
    '&:hover': {
      color: theme.typography.color.primary,
      cursor: 'pointer'
    },
    '&:visited': {
      color: theme.typography.color.primary
    },
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(20),
    opacity: 0.7
  },
  filterHeader: {
    padding: `${theme.typography.pxToRem(19)} ${theme.typography.pxToRem(
      25
    )} 0 ${theme.typography.pxToRem(25)}`
  },
  filterContent: {
    padding: `0 ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)}`
  }
});

class VcmUnitsFilters extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = props.initialFilters;
  }

  clearFilters = () => {
    this.setState(this.props.initialFilters);
    this.props.onApplyFilters(this.props.initialFilters);
  };

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    this.setState({ [name]: value });
  };

  makeFieldProps = (fieldName, fieldValue) => {
    return {
      name: fieldName,
      onChange: this.handleCheckboxChange,
      checked: fieldValue
    };
  };

  onFilter = () => {
     this.props.onApplyFilters(this.state);
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid
          container
          className={classes.filterHeader}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Filter</Typography>
          <Grid item>
            <a
              className={classes.clearFilters}
              onClick={() => this.clearFilters()}
            >
              Clear
            </a>
            <Button
              color="secondary"
              onClick={() => this.onFilter()}
              size="small"
              variant="contained"
            >
              <Typography variant="button">Filter</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.filterContent}>
          <Grid item>
            <FormGroup row name="filters">
              <FormControlLabel
                classes={{ root: classes.checkboxField }}
                control={<Checkbox {...this.makeFieldProps('in_review', this.state.in_review)}  />}
                label="In Review"
              />
              <FormControlLabel
                classes={{ root: classes.checkboxField }}
                control={<Checkbox {...this.makeFieldProps('approved', this.state.approved)} />}
                label="Approved"
              />
              <FormControlLabel
                
                classes={{ root: classes.checkboxField }}
                control={<Checkbox {...this.makeFieldProps('previously_approved', this.state.previously_approved)} />}
                label="Previously Approved"
              />
              <FormControlLabel
                classes={{ root: classes.checkboxField }}
                control={<Checkbox {...this.makeFieldProps('needs_attention', this.state.needs_attention)} />}
                label="Needs Attention"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(VcmUnitsFilters, styles, { withTheme: true });
