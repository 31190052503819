import React from 'react';
import { IconButton, Stack, Typography } from "@mui/material";
import TrashIcon from '@common/icons/TrashIcon';

const VcmUnitItem = ({ item, onDelete }) => {
  return (
    <Stack
      sx={{
        py: 2,
        px: 2,
        bgcolor: "#8686861A",
        border: "1px solid",
        borderColor: "divider",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: "10px",
      }}
    >
      <Stack
        sx={{ flexDirection: "row", flexGrow: 1, gap: 4, alignItems: "center" }}
      >
        <Typography sx={{ width: { xs: 100, md: 150 } }}>
          {item}
        </Typography>
      </Stack>
      <Stack>
        <IconButton onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default VcmUnitItem;
